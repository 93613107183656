import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ClassfieldPageModule } from './classfield/classfield.module';
import { HomePage } from './home.page';
import { HomePageRoutingModule } from './home-routing.module';
import { SharedModule } from '../_shared/shared.module';

import * as fromCategory from '../_shared/store/category/reducers/category.reducers';
import { categoryReducer } from '../_shared/store/category/reducers/category.reducers';
import { CategoryEffects } from '../_shared/store/category/effects/category-effects.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HomePageRoutingModule,
        SharedModule,
        ReactiveFormsModule,
        ClassfieldPageModule,
        StoreModule.forFeature(fromCategory.categoryFeatureKey, categoryReducer),
        EffectsModule.forFeature([CategoryEffects])
    ],
    exports: [
        HomePage
    ],
    declarations: [
        HomePage
    ]
})
export class HomePageModule {
}
